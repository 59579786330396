import { ConfigData } from './model/config-data';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { global_variables } from '../../../environments/global_variables';
import { AuthService } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { PurehttpService } from '../../services/purehttp.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { serverTimestamp } from 'firebase/database';
import * as _ from 'lodash-es';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { debounceTime, finalize, last, take, takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { ConfigDataService } from '../../services/config-data.service';
import { ConfigurationFile, StepConfig } from 'src/app/helpers/config-data';

@Component({
   selector: 'app-config-modal',
   templateUrl: './config-modal.component.html',
   styleUrls: ['./config-modal.component.scss'],
})
export class ConfigModalComponent implements OnInit, OnDestroy {
   configForm: FormGroup;
   numberForm: FormGroup;
   settingForm: FormGroup;

   arrIntModalTypes: number[];
   arrIntSamplingHours: number[];
   arrIntSamplingMinutes: number[];
   arrIntSamplingSeconds: number[];

   arrStrDataTypes: string[];
   arrStrStepTypes: string[];
   arrStrStepActions: string[];
   arrStrSetupTypes: string[];
   arrStrUserTypes: string[];
   arrStrAlgorithm: string[];
   arrStrDisplayedColumns: string[];

   arrObjChromatograms: Object[];
   arrObjCalibrations: Object[];
   arrObjUploadOptions: Object[];
   isCleaningUploadStep = false;
   isRepeatedSamplingInRunStep = false;
   arrObjUploadData: Object[];
   arrObjLoadedParam: Object[];
   arrObjChemicalCRs: Object[];
   arrObjChemicalCDs: Object[];
   arrObjChemicalPDs: Object[];
   arrObjConfigTypes: Object[];
   arrObjSamplingTimes: Object[];
   arrParams: Object[];
   arrConfigs: Object[];

   objTKeyTable: Object;
   objValuePumpKeyTable: Object;
   objSelectedSensor: any;
   objLoadedParam: Object;
   objConfigData: Object;
   objAssignedData: ConfigData;
   objConfigFormErrors: Object;

   strCurrentDataType: string;
   strCurrentSetupType: string;
   strCurrentUserType: string;
   strUserId: string;
   strConfigFileName: string;
   strCurrentStepAction: string;
   strCurrentAlgorithm: string;
   strSelectedFileKey: string;
   strLoadedConfigFileName: string;
   strCurrentConfigType: string;
   strFilerText: string;
   strAssignedKey: string;
   strAssignedConfigurationName: string;
   strConfigurationAlert: string;
   strEstimatedTotalTime: string;
   strConfigurationComment: string;

   bIsImportFile: boolean;
   bIsGetConfigurations: boolean;
   bIsLoadConfiguration: boolean;
   bIsConfigMessage: boolean;
   bIsAlertSuccess: boolean;
   bIsSaveConfiguration: boolean;
   bIsAlert: boolean;
   bIsUploadConfigurationToCloud: boolean;
   bIsGetCloudConfigs: boolean;
   bIsTryToGetCC: boolean;
   bIsOverTotalRunTime: boolean;
   bIsChemicalSaveData: boolean;
   bIsLoadForm: boolean;
   bIsMultiMRStep: boolean;
   bIsCorrectSimpleConfiguration: boolean;
   bIsGetAssignedCongiguration: boolean;
   bIsAssignMode: boolean;

   nCurrentStep: number;
   nStepNumber: number;
   nAlertType: number;
   nCurrentChroma: number;
   nCurrentCalib: number;
   nMinTotalRunTime: number;
   nChemicalCR: number;
   nChemicalCD: number;
   nChmicalPD: number;
   nChemicalStartTime: number;
   nChemicalTotal: number;
   nModalType: number;
   nMainStepNumber: number;
   nAssignedModalType: number;

   configFormSub: any;
   sensorDevicesSub: any;
   settingFormSub: any;

   dataSource: any;
   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild('table') table: any;
   private destroy$: Subject<boolean>;
   private dialogConfirmSub: Subscription;
   pageEvent: any;
   isFastModeEnabled = false;

   constructor(
      public dialogRef: MatDialogRef<ConfigModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public _snackBar: MatSnackBar,
      private _authService: AuthService,
      private _httpService: HttpService,
      private _purehttpService: PurehttpService,
      private _router: Router,
      private storage: AngularFireStorage,
      private dialog: MatDialog,
      private _notification: NotificationService,
      private configData: ConfigDataService
   ) {
      this.arrStrDataTypes = global_variables['DataTypes'];
      this.arrStrUserTypes = global_variables['userTypes'];
      this.arrStrStepActions = global_variables['stepActions'];
      this.arrStrAlgorithm = global_variables['algorithms'];
      this.arrStrStepTypes = global_variables['StepTypes'];
      this.arrIntModalTypes = global_variables['modalTypes'];
      this.arrStrSetupTypes = ['cloud', 'local', 'new'];
      this.arrStrDisplayedColumns = ['position', 'name', 'time', 'actions'];
      this.arrObjConfigTypes = [
         {
            label: 'Simple Configuration',
            value: 0,
         },
         {
            label: 'Complete Configuration',
            value: 1,
         },
      ];
      this.arrParams = [];
      this.arrConfigs = [];
      this.arrObjChromatograms = [];
      this.arrObjUploadData = [];
      this.arrObjCalibrations = [];
      this.arrObjUploadOptions = [];
      this.arrObjLoadedParam = [];
      this.arrObjChemicalCRs = [];
      this.arrObjChemicalCDs = [];
      this.arrObjChemicalPDs = [];
      this.arrIntSamplingHours = [];
      this.arrIntSamplingMinutes = [];
      this.arrIntSamplingSeconds = [];
      this.arrObjSamplingTimes = [];

      this.objSelectedSensor = data['sensor'];
      console.log(this.objSelectedSensor, 'SDAD');
      this.objConfigData = this.fillDefaultObjConfigData(data['configData']);
      // this.objConfigData = data['configData'];
      this.bIsAssignMode = data['isAssignMode'] ? data['isAssignMode'] : false;
      this.strCurrentDataType = this.arrStrDataTypes[0];

      this.strCurrentAlgorithm = this.arrStrAlgorithm[0];
      this.strCurrentStepAction = this.arrStrStepActions[0];
      this.strCurrentSetupType = this.arrStrSetupTypes[0];
      this.strCurrentConfigType = this.arrObjConfigTypes[0]['value'];
      this.nCurrentStep = -1;
      this.nModalType = -1;
      this.nStepNumber = 0;
      this.nMainStepNumber = 3;

      this.bIsImportFile = false;
      this.bIsGetConfigurations = false;
      this.bIsConfigMessage = false;
      this.bIsAlertSuccess = false;
      this.bIsSaveConfiguration = false;
      this.bIsAlert = false;
      this.bIsUploadConfigurationToCloud = false;
      this.bIsGetCloudConfigs = false;
      this.bIsTryToGetCC = false;
      this.bIsOverTotalRunTime = false;
      this.bIsChemicalSaveData = false;
      this.bIsLoadForm = false;
      this.bIsMultiMRStep = false;
      this.bIsCorrectSimpleConfiguration = true;
      this.bIsLoadConfiguration = false;
      this.bIsGetAssignedCongiguration = false;

      this.strSelectedFileKey = '';
      this.strLoadedConfigFileName = '';
      this.strEstimatedTotalTime = '';
      this.strConfigurationComment = '';
      this.objConfigFormErrors = <any>{};
      this.destroy$ = new Subject();
   }

   /**
    * check total sum of t1, t2 and t3
    */
   checkTotalRunTime() {
      const data = this.configForm['value'];
      console.log(data, 'data config form 🦄');
      this.nMinTotalRunTime = 0;
      if (this.objTKeyTable && this.objTKeyTable['rows'] && this.objTKeyTable['headers']) {
         console.log(this.objTKeyTable, 'this.objTKeyTable🦆🦆');
         for (let i = 0; i < this.objTKeyTable['rows'].length; i++) {
            let nTotal = 0;
            const row = this.objTKeyTable['rows'][i];

            for (let j = 2; j <= 4; j++) {
               const header = this.objTKeyTable['headers'][j];
               const strKey = row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1);

               if (data.hasOwnProperty(strKey)) {
                  nTotal += data[strKey];
               }
            }

            if (this.nMinTotalRunTime < nTotal) {
               this.nMinTotalRunTime = nTotal;
            }

            if (data['totalRuntime'] < this.nMinTotalRunTime) {
               this.bIsOverTotalRunTime = true;
            } else {
               this.bIsOverTotalRunTime = false;
            }
         }
      } else {
         this.bIsOverTotalRunTime = true;
         this._snackBar.open('Empty Form Key Table', 'Error', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
         });
      }
   }

   updateStrEstimatedTotalTime(estimatedTotalTime: string) {
      const path = `${environment.APIS.SENSORCONFIGS}/${this.objSelectedSensor.key}`;
      return this._httpService.updateAsObject(path, { Estimate_Label: estimatedTotalTime });
   }
   getAssignedConfiguration(): Promise<any> {
      return new Promise<void>((resolve, reject) => {
         if (this.objSelectedSensor && this.objSelectedSensor['key']) {
            this._httpService
               .getAsObject(`${environment.APIS.SENSORDEVICES}/${this.objSelectedSensor['key']}/fileKey`, 1)
               .subscribe({
                  next: (device: Object) => {
                     if (device && device['$value']) {
                        this._httpService
                           .getAsObject(`${environment.APIS.CONFIGURATIONS}/${device['$value']}`, 1)
                           .subscribe({
                              next: (res) => {
                                 console.log({ res });
                                 if (res) {
                                    this.nAssignedModalType = res['modalType'];
                                    this.strAssignedConfigurationName =
                                       res['path'] && res['path'].indexOf('/') > -1 ? res['path'].split('/').pop() : '';
                                    this.getAssignedConfigurationData(res['configUrl'])
                                       .then(() => {
                                          resolve();
                                       })
                                       .catch(() => {
                                          reject();
                                       });
                                 } else {
                                    resolve();
                                 }
                              },
                              error: error => {
                                 console.error('config-modal.component.ts -> getAssignedConfiguration(): ', error);

                                 reject();
                              }
                           });
                     } else {
                        resolve();
                     }
                  },
                  error: error => {
                     console.error('config-modal.component.ts -> getAssignedConfiguration(): ', error);

                     reject();
                  }
               });
         } else {
            resolve();
         }
      });
   }

   getAssignedConfigurationData(strLoadUrl: string): Promise<any> {
      return new Promise<void>((resolve, reject) => {
         const objPostData = {
            url: strLoadUrl,
         };
         const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud
         // let strFireFunctionUrl = environment.VARIABLES.FirebaseFunctionUrlLocal; // local

         this._purehttpService.callFirebaseFunction(`${strFireFunctionUrl}/getData`, objPostData).subscribe({
            next: (res: any) => {
               const data = this.configData.fillDefaultConfigProps(res.data);

               for (let i = 0; i < this.arrStrDataTypes.length; i++) {
                  const strTypeKey = this.arrStrDataTypes[i];
                  if (data && data.hasOwnProperty(strTypeKey) && data['Current_Type'] === strTypeKey) {
                     this.objAssignedData = data;
                     break;
                  }
               }

               resolve();
            },
            error: error => {
               console.error('config-modal.component.ts -> getAssignedConfiguration(): ', error);
            }
         });
      });
   }

   /**
    * handle modal status before clicking the previous and next step button
    */
   handleBeforeModal() {
      this.strSelectedFileKey = '';
      let configData: any;

      if (this.nCurrentStep === 0) {
         // Select the Configuration Type
         if (this.strCurrentConfigType === this.arrObjConfigTypes[0]['value']) {
            // simple configuration type
            this.initSettingForm();
         } else {
            if (this.objConfigData && this.objConfigData['Current_Modal_Type']) {
               this.nModalType = this.objConfigData['Current_Modal_Type'];
            } else {
               this.nModalType = this.arrIntModalTypes[2];
            }

            this.strCurrentDataType = this.arrStrDataTypes[0];
            this.bIsImportFile = false;
            this.bIsConfigMessage = false;
            this.getConfigList();
         }
      } else if (this.nCurrentStep === this.nMainStepNumber - 2) {
         // Import data
         this.initForm();
         this.bIsImportFile = false;
         this.arrParams = [];

         let objConfigData = this.objLoadedParam;

         if (
            this.strCurrentSetupType === this.arrStrSetupTypes[2] &&
            this.objConfigData &&
            this.objConfigData.hasOwnProperty(this.nModalType)
         ) {
            objConfigData = this.objConfigData[this.nModalType];
         }

         let stepNumber = 1;
         let runNumber = 1;

         if (objConfigData && objConfigData.hasOwnProperty(this.strCurrentDataType)) {
            configData = objConfigData[this.strCurrentDataType];
            stepNumber = configData['Num_of_Step'];
            runNumber = configData['Num_of_Cycle'];
         }

         this.numberForm.controls['stepNumber'].setValue(stepNumber);
         this.numberForm.controls['runNumber'].setValue(runNumber);

         this.clearCloudConfigList();
      } else if (this.nCurrentStep === this.nMainStepNumber - 1) {
         // select the numbers for the configuration
         const nStepNumber = parseInt(this.numberForm['value']['stepNumber'], 10);
         // the case when changing the array params
         if (configData) {
            // when data's step number is not same as the input value
            if (configData['Num_of_Step'] !== nStepNumber) {
               this.arrParams = [];
            }
         } else {
            // when existing step number is not same as the input value
            if (this.nStepNumber !== nStepNumber) {
               this.arrParams = [];
            }
         }

         this.nStepNumber = nStepNumber;
      } else if (
         this.nCurrentStep >= this.nMainStepNumber &&
         this.nCurrentStep < this.nStepNumber + this.nMainStepNumber
      ) {
         // steps for the configurations
         const nStep = this.nCurrentStep - this.nMainStepNumber;
         let data = <any>{};

         switch (this.strCurrentStepAction) {
            case this.arrStrStepActions[0]:
               // Measurement Run
               data = this.configForm['value'];

               this.checkTotalRunTime();
               if (this.bIsOverTotalRunTime) {
                  return;
               }
               break;

            case this.arrStrStepActions[1]:
               // Data Processing
               if (this.arrObjChromatograms.length > 0) {
                  data['algorithm'] = this.strCurrentAlgorithm;
                  data['chroma'] = this.nCurrentChroma;

                  if (this.nModalType === this.arrIntModalTypes[0]) {
                     data['calib'] = this.nCurrentCalib;
                  }
               }
               break;

            case this.arrStrStepActions[2]:
               // Data Upload
               if (this.arrObjUploadOptions.length > 0) {
                  data['uploads'] = this.arrObjUploadData;
                  data['isCleaning'] = this.isCleaningUploadStep;
                  data['isRepeatedSamplingInRun'] = this.isRepeatedSamplingInRunStep;
               }
               break;

            case this.arrStrStepActions[3]:
               // Chemical Recognition
               if (
                  this.arrObjChemicalCRs.length > 0 &&
                  this.arrObjChemicalCDs.length > 0 &&
                  this.arrObjChemicalPDs.length > 0
               ) {
                  data['chroma'] = this.nChemicalCR;
                  data['processed'] = this.nChmicalPD;
                  data['start'] = this.nChemicalStartTime;
                  data['total'] = this.nChemicalTotal;
                  if (this.nModalType === this.arrIntModalTypes[0]) {
                     data['calib'] = this.nChemicalCD;
                     data['enabled'] = this.bIsChemicalSaveData;
                  }
               }
               break;
         }

         data['stepAction'] = this.strCurrentStepAction;

         this.arrParams[nStep] = data;
         console.log(data);
         this.bIsUploadConfigurationToCloud = false;
         this.bIsAlert = false;
         this.bIsSaveConfiguration = false;
      }

      return true;
   }

   /**
    * handle modal status after clicking next and previous button
    */
   handleAfterModal() {
      if (this.nCurrentStep === 2 && this.strCurrentSetupType === this.arrStrSetupTypes[0]) {
         this.getConfigList();
      }

      if (this.nCurrentStep === this.nMainStepNumber - 1) {
         // set the configurations for params form

         let objLoadedParam: Object;
         this.bIsMultiMRStep = false;

         if (this.strCurrentSetupType !== this.arrStrSetupTypes[0]) {
            this.strLoadedConfigFileName = '';
         }

         if (
            this.strCurrentSetupType !== this.arrStrSetupTypes[2] &&
            this.objLoadedParam &&
            this.strCurrentDataType === this.objLoadedParam['Current_Type']
         ) {
            objLoadedParam = this.objLoadedParam[this.strCurrentDataType];
         }

         if (
            this.objConfigData &&
            this.objConfigData.hasOwnProperty(this.nModalType) &&
            this.objConfigData[this.nModalType].hasOwnProperty(this.strCurrentDataType)
         ) {
            if (this.strCurrentSetupType === this.arrStrSetupTypes[2]) {
               objLoadedParam = this.objConfigData[this.nModalType][this.strCurrentDataType]; // TODO add Nafion to config
            }

            const configData = this.objConfigData[this.nModalType][this.strCurrentDataType];

            if (
               configData['Mode_Config']['Step2_Config'] &&
               configData['Mode_Config']['Step2_Config']['stepAction'] === this.arrStrStepActions[0]
            ) {
               this.bIsMultiMRStep = true;
            }
         }

         this.arrObjLoadedParam = [];
         if (
            objLoadedParam &&
            objLoadedParam['Num_of_Step'] === this.numberForm['value']['stepNumber'] &&
            objLoadedParam.hasOwnProperty('Mode_Config')
         ) {
            for (let i = 1; i <= objLoadedParam['Num_of_Step']; i++) {
               const keyName = `Step${i}_Config`;

               if (objLoadedParam['Mode_Config'].hasOwnProperty(keyName)) {
                  this.arrObjLoadedParam.push(objLoadedParam['Mode_Config'][keyName]);
               }
            }
         }
      }

      const nParamStep = this.nCurrentStep - this.nMainStepNumber; // current param configuration step
      if (nParamStep >= 0) {
         this.generateStepsAvailable(nParamStep);
         // if arrParams is already existed, otherwise this step is not first time
         if (this.arrParams[nParamStep]) {
            this.setParam(this.arrParams[nParamStep], true);
         } else if (this.arrObjLoadedParam[nParamStep]) {
            this.setParam(this.arrObjLoadedParam[nParamStep], false);
         } else {
            this.initForm(false);
         }
      }

      if (this.nCurrentStep >= this.nMainStepNumber && this.nCurrentStep < this.nStepNumber + this.nMainStepNumber) {
         this.checkTotalRunTime();
      }
   }

   /**
    * set the param to init the step form
    * @param params to init
    * @param bIsTracked to check if the param is from this.arrParams variable
    */
   setParam(params: Object, bIsTracked: boolean) {
      this.strCurrentStepAction = params && params['stepAction'] ? params['stepAction'] : this.arrStrStepActions[0];
      this.arrObjUploadData = [];
      this.isCleaningUploadStep = false;

      if (this.arrObjChromatograms.length > 0) {
         this.nCurrentChroma = this.arrObjChromatograms[0]['value'];
         if (this.nModalType === this.arrIntModalTypes[0]) {
            this.nCurrentCalib = this.arrObjChromatograms[0]['value'];
         }
         this.nCurrentChroma = this.arrObjChromatograms[0]['value'];
      }

      if (this.arrObjUploadOptions.length > 0) {
         this.arrObjUploadData.push({ step: this.arrObjUploadOptions[0]['value'] });
      }

      switch (this.strCurrentStepAction) {
         case this.arrStrStepActions[0]:
            // Measurement Run
            if (params) {
               if (bIsTracked) {
                  this.setFormParam(params);
               } else {
                  this.initForm(false, params);
               }
            }
            break;
         case this.arrStrStepActions[1]:
            this.strCurrentAlgorithm = params && params['algorithm'] ? params['algorithm'] : this.arrStrAlgorithm[0];
            this.nCurrentChroma = params && params['chroma'] ? params['chroma'] : this.arrObjChromatograms[0]['value'];
            if (this.nModalType === this.arrIntModalTypes[0]) {
               this.nCurrentCalib = params && params['calib'] ? params['calib'] : this.arrObjChromatograms[0]['value'];
            }
            break;

         case this.arrStrStepActions[2]:
            this.isCleaningUploadStep = false;
            this.isCleaningUploadStep = params && params['isCleaning'];
            this.isRepeatedSamplingInRunStep = params && params['isRepeatedSamplingInRun'];

            this.arrObjUploadData = [];
            this.arrObjUploadData =
               params && params['uploads']
                  ? params['uploads']
                  : this.arrObjUploadData.push({ step: this.arrObjUploadOptions[0]['value'] });
            break;

         case this.arrStrStepActions[3]:
            this.nChemicalCR = params['chroma'] ? params['chroma'] : this.arrObjChemicalCRs[0];
            this.nChmicalPD = params['processed'] ? params['processed'] : this.arrObjChemicalPDs[0];
            this.nChemicalStartTime = params['start'] ? params['start'] : 12;
            this.nChemicalTotal = params['total'] ? params['total'] : 0;
            if (this.nModalType === this.arrIntModalTypes[0]) {
               this.nChemicalCD = params['calib'] ? params['calib'] : this.arrObjChemicalCDs[0];
               this.bIsChemicalSaveData = params['enabled'];
            }
            break;
      }

      if (this.arrObjChemicalCRs.length > 0 && !this.nChemicalCR) {
         this.nChemicalCR = this.arrObjChemicalCRs[0]['value'];
      }

      if (this.arrObjChemicalCDs.length > 0 && !this.nChemicalCD) {
         this.nChemicalCD = this.arrObjChemicalCDs[0]['value'];
      }

      if (this.arrObjChemicalPDs.length > 0 && !this.nChmicalPD) {
         this.nChmicalPD = this.arrObjChemicalPDs[0]['value'];
      }

      if (!this.nChemicalStartTime) {
         this.nChemicalStartTime = 12;
      }

      if (!this.nChemicalTotal) {
         this.nChemicalTotal = 0;
      }

      if (!this.bIsChemicalSaveData) {
         this.bIsChemicalSaveData = false;
      }
   }

   /**
    * Check category existance in Data processing and upload action step
    * @param nStep step number of this.arrParams's
    * @param strCategory category name in this.arrStrStepTypes
    */
   checkCategoryExistance(nStep: number, strCategory: string) {
      if (nStep < 0) {
         return false;
      }

      if (this.arrParams[nStep]['stepAction'] !== this.arrStrStepActions[0]) {
         // if it is no Measurement Run
         return false;
      }

      if (this.arrParams[nStep]['stepType'] === strCategory) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * get all steps available for a reference
    * @param nParamStep parameter step number
    */
   generateStepsAvailable(nParamStep: number) {
      this.arrObjChromatograms = [];
      this.arrObjUploadOptions = [];
      this.arrObjChemicalCRs = [];
      this.arrObjChemicalCDs = [];
      this.arrObjChemicalPDs = [];

      if (nParamStep > 0) {
         for (let i = 0; i < nParamStep; i++) {
            if (this.arrParams[i] && this.arrParams[i]['stepAction']) {
               if (this.arrParams[i]['stepAction'] === this.arrStrStepActions[0]) {
                  this.arrObjChromatograms.push({
                     value: i + 1,
                     label: `Step${i + 1} ${this.arrParams[i]['stepType']}`,
                  });

                  if (this.arrParams[i]['saveData']) {
                     this.arrObjUploadOptions.push({
                        value: i + 1,
                        label: `Step${i + 1} ${this.arrParams[i]['stepType']}`,
                     });
                  }

                  if (this.arrParams[i]['stepType'] === this.arrStrStepTypes[3]) {
                     // Separation Category
                     this.arrObjChemicalCRs.push({
                        value: i + 1,
                        label: `Step${i + 1} ${this.arrParams[i]['stepType']}`,
                     });
                  }

                  if (this.arrParams[i]['stepType'] === this.arrStrStepTypes[5]) {
                     // Calibrate Flow Category
                     this.arrObjChemicalCDs.push({
                        value: i + 1,
                        label: `Step${i + 1} ${this.arrParams[i]['stepType']}`,
                     });
                  }
               }

               if (this.arrParams[i]['stepAction'] === this.arrStrStepActions[1]) {
                  // Data process step action
                  this.arrObjUploadOptions.push({
                     value: i + 1,
                     label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                  });

                  this.arrObjChemicalPDs.push({
                     value: i + 1,
                     label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                  });

                  if (
                     this.checkCategoryExistance(this.arrParams[i]['chroma'] - 1, this.arrStrStepTypes[3]) ||
                     this.checkCategoryExistance(
                        this.arrParams[i].hasOwnProperty('calib') ? this.arrParams[i]['calib'] - 1 : -1,
                        this.arrStrStepTypes[3]
                     )
                  ) {
                     // Check Separation Category
                     this.arrObjChemicalCRs.push({
                        value: i + 1,
                        label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                     });
                  }

                  if (
                     this.checkCategoryExistance(this.arrParams[i]['chroma'] - 1, this.arrStrStepTypes[5]) ||
                     this.checkCategoryExistance(
                        this.arrParams[i].hasOwnProperty('calib') ? this.arrParams[i]['calib'] - 1 : -1,
                        this.arrStrStepTypes[5]
                     )
                  ) {
                     // Check Calibrate Flow Category
                     this.arrObjChemicalCDs.push({
                        value: i + 1,
                        label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                     });
                  }
               }

               if (this.arrParams[i]['stepAction'] === this.arrStrStepActions[2]) {
                  // Data Upload step action
                  if (this.arrParams[i]['uploads'].length > 0) {
                     for (let j = 0; j < this.arrParams[i]['uploads'].length; j++) {
                        if (
                           this.checkCategoryExistance(
                              this.arrParams[i]['uploads'][j]['step'] - 1,
                              this.arrStrStepTypes[3]
                           )
                        ) {
                           // Check Separation Category
                           this.arrObjChemicalCRs.push({
                              value: i + 1,
                              label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                           });
                        }

                        if (
                           this.checkCategoryExistance(
                              this.arrParams[i]['uploads'][j]['step'] - 1,
                              this.arrStrStepTypes[5]
                           )
                        ) {
                           // Check Calibrate Flow Category
                           this.arrObjChemicalCDs.push({
                              value: i + 1,
                              label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                           });
                        }
                     }
                  }
               }

               if (this.arrParams[i]['stepAction'] === this.arrStrStepActions[3]) {
                  // Chemical recognition step action
                  this.arrObjUploadOptions.push({
                     value: i + 1,
                     label: `Step${i + 1} ${this.arrParams[i]['stepAction']}`,
                  });
               }
            }
         }
      }

      this.arrObjCalibrations = _.cloneDeep(this.arrObjChromatograms);
      this.arrObjCalibrations.push({
         value: -1,
         label: 'N/A',
      });
      this.arrObjChemicalCDs.push({
         value: -1,
         label: 'N/A',
      });
      this.arrObjChemicalPDs.push({
         value: -1,
         label: 'N/A',
      });
   }

   /**
    * process next step
    */
   nextStep() {
      this.handleBeforeModal();
      const bIsGo = this.checkGoAvailability();
      if (bIsGo) {
         this.nCurrentStep++;
         this.handleAfterModal();
      }
   }

   /**
    * process previous step
    */
   previousStep() {
      this.handleBeforeModal();
      const bIsGo = this.checkGoAvailability();

      if (bIsGo) {
         this.nCurrentStep--;
         this.handleAfterModal();
      }
   }

   /**
    * check simple configuration
    */
   checkSimpleConfiguration() {
      if (this.objSelectedSensor['fastMode']) {
         // console.log(this.objLoadedParam, 'this.objLoadedParam');
         this.alertMessage(true, '');

         if (this.objAssignedData &&
            this.objAssignedData['Current_Type'] &&
            this.strCurrentConfigType === this.arrObjConfigTypes[0]['value']) {

            let samplingTimeSeconds = 0;
            const currentType = this.objLoadedParam['Current_Type'];
            const idleTime = Math.floor(this.settingForm['value']['idleTime']);
            const runNumber = Math.floor(this.settingForm['value']['runNumber']);

            this.objLoadedParam[currentType]['Idle_Time'] = idleTime;
            this.objLoadedParam[currentType]['Num_of_Cycle'] = runNumber;

            let samplingStep = -1;
            let idleStep = -1;
            let totalSum = 0;
            let otherSum = 0;
            const configData = this.objLoadedParam[currentType]['Mode_Config'];

            this.bIsCorrectSimpleConfiguration = true;

            if (configData) {
               let keyIndex = 0;
               for (const key in configData) {
                  if (configData[key]) {
                     keyIndex++;

                     switch (configData[key]['stepAction']) {
                        case this.arrStrStepActions[0]: // Measurement Run
                           const stepTypes = [
                              'Forward Purge', 'Backward Purge', 'Sampling',
                              'Separation', 'Idle', 'Calibrate Flow', 'Other'
                           ];

                           const stepTypeIndex = stepTypes.indexOf(configData[key]['Step_Type']);
                           if (stepTypeIndex !== -1) {
                              if (!configData[key]['isCleaning']) {
                                 totalSum += configData[key]['Total_Run_Time'];
                                 otherSum += configData[key]['Total_Run_Time'];
                              }
                              if (stepTypeIndex === 2) { // Sampling step
                                 samplingStep = keyIndex;
                                 samplingTimeSeconds += configData[key]['Total_Run_Time'];
                              }
                           }

                           if (stepTypeIndex === 4) { // Idle step
                              idleStep = keyIndex;
                              if (this.nModalType === this.arrIntModalTypes[1] ||
                                 this.nModalType === this.arrIntModalTypes[2]) {
                                 this.objLoadedParam[currentType]['Mode_Config'][key]['Total_Run_Time'] = idleTime;
                              }
                           }
                           break;

                        case this.arrStrStepActions[1]: // Data Processing
                           totalSum += 12;
                           otherSum += 12;
                           break;

                        case this.arrStrStepActions[2]: // Data Upload
                           totalSum += 2;
                           otherSum += 2;
                           break;

                        case this.arrStrStepActions[3]: // Chemical Recognition
                           totalSum += 5;
                           otherSum += 5;
                           break;
                     }
                  }
               }
            }

            if ((samplingStep < 1 || idleStep < 1) && !this.objSelectedSensor['fastMode']) {
               this.bIsCorrectSimpleConfiguration = false;
               this.alertMessage(false, 'This configuration must have a sampling and an idle step.');
               return {
                  correct: false,
                  samplingStep: -1,
                  idleStep: -1,
               };
            }

            return {
               correct: true,
               samplingStep: samplingStep,
               idleStep: idleStep,
               totalSum: totalSum * runNumber,
               otherSum: otherSum,
               samplingTimeSeconds: samplingTimeSeconds,
            };
         } else {
            return {
               correct: false,
               samplingStep: -1,
               idleStep: -1,
            };
         }
      } else {
         return this.handleNonFastMode();
      }
   }


   handleNonFastMode() {
      this.alertMessage(true, '');

      if (this.objLoadedParam &&
         this.objLoadedParam['Current_Type'] &&
         this.strCurrentConfigType === this.arrObjConfigTypes[0]['value']) {

         const samplingTime = this.formatSamplingTime();

         if (samplingTime[0] + samplingTime[1] + samplingTime[2] === 0) {
            this.bIsCorrectSimpleConfiguration = false;
            this.alertMessage(false, '00:00:00 Sampling Time is disallowed.');
            return {
               correct: false,
               samplingStep: -1,
               idleStep: -1,
            };
         }

         const fastMode = this.objSelectedSensor['fastMode'];
         const samplingTimeSeconds = samplingTime[0] * 3600 + samplingTime[1] * 60 + samplingTime[2];
         const currentType = this.objLoadedParam['Current_Type'];

         if (!fastMode) {
            this.objLoadedParam[currentType]['Sampling_Time'] = samplingTimeSeconds;
         }

         const idleTime = Math.floor(this.settingForm['value']['idleTime']);
         const runNumber = Math.floor(this.settingForm['value']['runNumber']);

         this.objLoadedParam[currentType]['Idle_Time'] = idleTime;
         this.objLoadedParam[currentType]['Num_of_Cycle'] = runNumber;

         let samplingStep = -1;
         let idleStep = -1;
         let totalSum = 0;
         let otherSum = 0;
         const configData = this.objLoadedParam[currentType]['Mode_Config'];
         this.bIsCorrectSimpleConfiguration = true;

         if (configData) {
            let keyIndex = 0;
            for (const key in configData) {
               if (configData[key]) {
                  keyIndex++;

                  switch (configData[key]['stepAction']) {
                     case this.arrStrStepActions[0]: // Measurement Run
                        if (configData[key]['Step_Type'] === 'Sampling') {
                           samplingStep = keyIndex;

                           if (this.nModalType === this.arrIntModalTypes[1]) { // omni-2000
                              if (!fastMode) {
                                 if (this.objLoadedParam[currentType]['Mode_Config'][key] &&
                                    this.objLoadedParam[currentType]['Mode_Config'][key]['KP1']) {
                                    this.objLoadedParam[currentType]['Mode_Config'][key]['KP1']['t3'] = samplingTimeSeconds;
                                 }
                              }
                              this.objLoadedParam[currentType]['Mode_Config'][key]['Total_Run_Time'] =
                                 this.objLoadedParam[currentType]['Mode_Config'][key]['KP1']['t3'] + 200;
                           } else if (this.nModalType === this.arrIntModalTypes[2]) { // omni-2100
                              if (!fastMode) {
                                 if (configData[key]['isRepeatedSamplingInRun']) {

                                    this.modifyConfigData(this.objLoadedParam, samplingTimeSeconds);

                                 } else {
                                    this.objLoadedParam[currentType]['Mode_Config'][key]['Sampling_Pump']['t3'] = samplingTimeSeconds;
                                 }
                              }
                              this.objLoadedParam[currentType]['Mode_Config'][key]['Total_Run_Time'] =
                                 this.objLoadedParam[currentType]['Mode_Config'][key]['Sampling_Pump']['t3'] +
                                 parseInt(this.objLoadedParam[currentType]['Mode_Config'][key]['Sampling_Pump']['t1'], 10) + 3;
                           }
                        }
                        else {
                           // Exclude sampling and sampling-repeated steps from "otherSum"
                           if (!configData[key]['isRepeatedSamplingInRun']) {
                              otherSum += this.objLoadedParam[currentType]['Mode_Config'][key]['Total_Run_Time'];
                           } else {


                              if (configData[key] && configData[key]['Total_Run_Time']) {
                                 // Exclude both 'Sampling' and 'Sampling-repeated' steps
                                 if (configData[key]['Step_Type'] !== 'Sampling' && configData[key]['Step_Type'] !== 'Sampling-repeated') {
                                    console.log(`Including step: ${key}, Total_Run_Time: ${configData[key]['Total_Run_Time']} minutes`);
                                    otherSum += configData[key]['Total_Run_Time'];
                                 } else {
                                    console.log(`Excluding step: ${key}, as it is a ${configData[key]['Step_Type']} step.`);
                                 }
                              }


                              console.log(`Total required processing time: ${otherSum} minutes`);
                           }
                        }

                        break;

                     case this.arrStrStepActions[1]: // Data Processing
                        totalSum += 30;
                        otherSum += 30;
                        break;

                     case this.arrStrStepActions[2]: // Data Upload
                        totalSum += 0;
                        otherSum += 0;
                        break;

                     case this.arrStrStepActions[3]: // Chemical Recognition
                        totalSum += 0;
                        otherSum += 0;
                        this.objLoadedParam[currentType]['Mode_Config'][key]['total'] = samplingTimeSeconds / 3600;
                        break;
                  }
               }
            }
            idleStep = this.getIdleStepNumber(this.objLoadedParam);

            return {
               correct: true,
               samplingStep: samplingStep,
               idleStep: idleStep,
               totalSum: totalSum * runNumber,
               otherSum: otherSum,
               samplingTimeSeconds: samplingTimeSeconds,
            };
         } else {
            return {
               correct: false,
               samplingStep: -1,
               idleStep: -1,
            };
         }
      } else {
         return {
            correct: false,
            samplingStep: -1,
            idleStep: -1,
         };
      }
   }
   public getIdleStepNumber(data: any): number | null {
      const modeConfig = data.Frac_Delta.Mode_Config;
      let idleStep: number | null = null;

      // Iterate through the Mode_Config object
      for (const key in modeConfig) {
         if (modeConfig.hasOwnProperty(key)) {
            const stepConfig = modeConfig[key];

            // Extract the step number from the key (e.g., "Step10_Config" -> 10)
            const stepNumberMatch = key.match(/\d+/); // Extract digits from the key
            const stepNumber = stepNumberMatch ? parseInt(stepNumberMatch[0], 10) : null;

            if (stepNumber !== null) {
               // Check if the stepAction is "Measurement Run" and Step_Type is "Idle"
               if (stepConfig.stepAction === "Measurement Run" && stepConfig.Step_Type === "Idle") {
                  idleStep = stepNumber; // Return the extracted step number
                  break; // Exit loop once the idle step is found
               }
            }
         }
      }

      return idleStep; // Return the idle step number or null if not found
   }


   // public modifyConfigData1(data: any, samplingTimeSeconds: any) {
   //    const modeConfig = data.Frac_Delta.Mode_Config;
   //    const steps = [];

   //    // Reset repeated steps and t3 values
   //    for (const key in modeConfig) {
   //       if (modeConfig.hasOwnProperty(key)) {
   //          const stepConfig = modeConfig[key];
   //          // Ensure stepConfig and Step_Type are valid before proceeding
   //          if (stepConfig && stepConfig.Step_Type && stepConfig.Step_Type.includes('-repeated')) {
   //             delete modeConfig[key];
   //          } else if (stepConfig && stepConfig.Step_Type === global_variables['StepTypes'][2]) {
   //             stepConfig.Sampling_Pump.t3 = samplingTimeSeconds; // Reset t3 value
   //          }
   //       }
   //    }

   //    // Collect all steps into an array
   //    for (const key in modeConfig) {
   //       if (modeConfig.hasOwnProperty(key)) {
   //          const stepConfig = modeConfig[key];
   //          steps.push(stepConfig);
   //       }
   //    }

   //    let newSteps = [];
   //    let repeatBlock = [];
   //    let lastSamplingRepeatedIndex = -1;
   //    let maxSamplingTimePerStep = 0;
   //    let n = 1;
   //    let totalSamplingRepeatedTime = 0;
   //    let remainingSamplingTimeForLastSamplingRepeatedStep = 0;
   //    let totalSamplingTime = 0;
   //    let separationStepNumber = null;
   //    let processingStepNumber = null;
   //    let remainingSamplingTimeForOneRepeatedStep = 0;
   //    let uploadSteps = [];
   //    let diff = 0;

   //    // Store original upload steps before modification
   //    // steps.forEach((step) => {
   //    //    if (step.stepAction === "Data Upload") {
   //    //       uploadSteps.push({
   //    //          ...step,
   //    //          uploads: [...step.uploads] // Preserve the original order
   //    //       });
   //    //    }
   //    // });

   //    for (let i = 0; i < steps.length; i++) {
   //       const step = steps[i];

   //       if (step.isRepeatedSamplingInRun) {
   //          if (step.Step_Type === global_variables['StepTypes'][2]) {
   //             maxSamplingTimePerStep = step.Max_Sampling_Time_Per_Step;
   //             n = (samplingTimeSeconds < maxSamplingTimePerStep) ? 0 : Math.floor(samplingTimeSeconds / maxSamplingTimePerStep);
   //             totalSamplingRepeatedTime = n * maxSamplingTimePerStep;

   //             if (samplingTimeSeconds < maxSamplingTimePerStep) {
   //                step.Sampling_Pump.t3 = samplingTimeSeconds;
   //                step.Total_Run_Time = step.Sampling_Pump.t3 + step.Sampling_Pump.t1 + 3;
   //             } else {
   //                step.Sampling_Pump.t3 = step.Max_Sampling_Time_Per_Step;
   //                step.Total_Run_Time = step.Sampling_Pump.t3 + step.Sampling_Pump.t1 + 3;
   //             }

   //             remainingSamplingTimeForLastSamplingRepeatedStep = samplingTimeSeconds - totalSamplingRepeatedTime;
   //             remainingSamplingTimeForOneRepeatedStep = samplingTimeSeconds - maxSamplingTimePerStep;
   //          }
   //          repeatBlock.push(step);
   //       } else {
   //          if (repeatBlock.length > 0) {
   //             newSteps.push(...repeatBlock);

   //             for (let j = 0; j < n; j++) {
   //                repeatBlock.forEach((repeatStep) => {
   //                   const repeatedConfig = {
   //                      ...repeatStep,
   //                      Step_Type: repeatStep.Step_Type
   //                         ? `${repeatStep.Step_Type}-repeated`
   //                         : 'Undefined-Step_Type' // Safeguard for undefined Step_Type
   //                   };
   //                   newSteps.push(repeatedConfig);
   //                   if (repeatedConfig.Step_Type === 'Sampling-repeated') {
   //                      lastSamplingRepeatedIndex = newSteps.length - 1;
   //                   }
   //                });
   //             }
   //             repeatBlock = [];
   //          }
   //          newSteps.push(step);
   //       }
   //    }

   //    if (lastSamplingRepeatedIndex !== -1 && remainingSamplingTimeForLastSamplingRepeatedStep !== 0) {
   //       newSteps[lastSamplingRepeatedIndex] = {
   //          ...newSteps[lastSamplingRepeatedIndex],
   //          Sampling_Pump: {
   //             ...newSteps[lastSamplingRepeatedIndex].Sampling_Pump,
   //             t3: remainingSamplingTimeForLastSamplingRepeatedStep
   //          }
   //       };
   //       newSteps[lastSamplingRepeatedIndex].Total_Run_Time = newSteps[lastSamplingRepeatedIndex].Sampling_Pump.t3 + newSteps[lastSamplingRepeatedIndex].Sampling_Pump.t1 + 3;
   //    }

   //    // Log the index of Sampling-repeated step if there's exactly one
   //    let samplingRepeatedCount = 0;
   //    let samplingRepeatedIndex = -1;
   //    newSteps.forEach((step, index) => {
   //       if (step.Step_Type === 'Sampling-repeated') {
   //          samplingRepeatedCount++;
   //          samplingRepeatedIndex = index;
   //       }
   //    });

   //    if (samplingRepeatedCount === 1) {
   //       newSteps[samplingRepeatedIndex].Sampling_Pump.t3 = remainingSamplingTimeForOneRepeatedStep;
   //       newSteps[samplingRepeatedIndex].Total_Run_Time = newSteps[samplingRepeatedIndex].Sampling_Pump.t3 + newSteps[samplingRepeatedIndex].Sampling_Pump.t1 + 3;
   //    }

   //    // Convert newSteps back to modeConfig and update
   //    newSteps.forEach((step, index) => {
   //       modeConfig[`Step${index + 1}_Config`] = step;
   //    });

   //    // Remove any extra steps
   //    for (let i = newSteps.length + 1; modeConfig[`Step${i}_Config`]; i++) {
   //       delete modeConfig[`Step${i}_Config`];
   //    }

   //    // Update steps based on their stepAction
   //    newSteps.forEach((step, index) => {
   //       switch (step.stepAction) {
   //          case 'Data Processing':
   //             step.chroma = separationStepNumber;
   //             processingStepNumber = index + 1;
   //             break;

   //          case 'Data Upload':
   //             let dataUploadStepNumber = [];
   //             let dataUploadNewStepNumber = [];

   //             const stepsResult = this.countDataUploadSteps(steps);
   //             const stepCount = stepsResult.count;
   //             dataUploadStepNumber.push(...stepsResult.steps);

   //             const newStepsResult = this.countDataUploadSteps(newSteps);
   //             dataUploadNewStepNumber.push(...newStepsResult.steps);

   //             diff = dataUploadNewStepNumber[0] - dataUploadStepNumber[0];
   //             break;

   //          case 'Chemical Recognition':
   //             step.Total_Sampling_Time_Hrs = parseFloat((totalSamplingTime / 3600).toFixed(2));
   //             step.chroma = separationStepNumber;
   //             step.processed = processingStepNumber;
   //             break;
   //       }
   //    });

   //    this.modifyUploadsWithDiff(uploadSteps, diff);
   //    data.Frac_Delta.Num_of_Step = Object.keys(modeConfig).length;
   //    data.Frac_Delta.Mode_Config = modeConfig;

   //    console.log("Final modified dataUploads:", modeConfig);
   // }

   public modifyConfigData(data: any, samplingTimeSeconds: any) {
      const modeConfig = data.Frac_Delta.Mode_Config;
      const steps = [];

      // Reset repeated steps and t3 values
      for (const key in modeConfig) {
         if (modeConfig.hasOwnProperty(key)) {
            const stepConfig = modeConfig[key];
            if (stepConfig.Step_Type && stepConfig.Step_Type.includes('-repeated')) {
               delete modeConfig[key];
            } else if (stepConfig.Step_Type === global_variables['StepTypes'][2]) {
               stepConfig.Sampling_Pump.t3 = samplingTimeSeconds; // Reset t3 value
            }
         }
      }

      // Collect all steps into an array
      for (const key in modeConfig) {
         if (modeConfig.hasOwnProperty(key)) {
            const stepConfig = modeConfig[key];
            steps.push(stepConfig);
         }
      }

      // Preserve the original upload steps before modifying anything
      let originalUploadSteps = steps.filter(step => step.stepAction === "Data Upload").map(step => ({
         ...step,
         uploads: [...step.uploads] // Preserve the original order of uploads
      }));

      // Logic for handling repeated sampling steps and modifying based on samplingTimeSeconds
      let newSteps = [];
      let repeatBlock = [];
      let lastSamplingRepeatedIndex = -1;
      let maxSamplingTimePerStep = 0;
      let n = 1;
      let totalSamplingRepeatedTime = 0;
      let remainingSamplingTimeForLastSamplingRepeatedStep = 0;
      let totalSamplingTime = 0;
      let separationStepNumber = null;
      let processingStepNumber = null;
      let remainingSamplingTimeForOneRepeatedStep = 0;
      let diff = 0;

      for (let i = 0; i < steps.length; i++) {
         const step = steps[i];
         if (step.isRepeatedSamplingInRun) {
            if (step.Step_Type === global_variables['StepTypes'][2]) {
               maxSamplingTimePerStep = step.Max_Sampling_Time_Per_Step;
               n = (samplingTimeSeconds < maxSamplingTimePerStep) ? 0 : Math.floor(samplingTimeSeconds / maxSamplingTimePerStep);
               totalSamplingRepeatedTime = n * maxSamplingTimePerStep;
               if (samplingTimeSeconds < maxSamplingTimePerStep) {
                  step.Sampling_Pump.t3 = samplingTimeSeconds;
                  let samplingTime = step.Sampling_Pump.t3 + step.Sampling_Pump.t1 + 3;
                  step.Total_Run_Time = samplingTime;
               } else {
                  step.Sampling_Pump.t3 = step.Max_Sampling_Time_Per_Step;
                  let samplingTime = step.Sampling_Pump.t3 + step.Sampling_Pump.t1 + 3;
                  step.Total_Run_Time = samplingTime;
               }

               remainingSamplingTimeForLastSamplingRepeatedStep = samplingTimeSeconds - totalSamplingRepeatedTime;
               remainingSamplingTimeForOneRepeatedStep = samplingTimeSeconds - maxSamplingTimePerStep;
            }
            repeatBlock.push(step);
         } else {
            if (repeatBlock.length > 0) {
               newSteps.push(...repeatBlock);

               if (remainingSamplingTimeForLastSamplingRepeatedStep === 0) {
                  for (let j = 0; j < (n - 1); j++) {
                     repeatBlock.forEach((repeatStep, index) => {
                        const repeatedConfig = {
                           ...repeatStep,
                           Step_Type: repeatStep.Step_Type.includes('-repeated')
                              ? repeatStep.Step_Type
                              : `${repeatStep.Step_Type}-repeated`
                        };
                        newSteps.push(repeatedConfig);
                        if (repeatedConfig.Step_Type === 'Sampling-repeated') {
                           lastSamplingRepeatedIndex = newSteps.length - 1; // Track the index
                        }
                     });
                  }
               } else {
                  for (let j = 0; j < n; j++) {
                     repeatBlock.forEach((repeatStep, index) => {
                        const repeatedConfig = {
                           ...repeatStep,
                           Step_Type: repeatStep.Step_Type.includes('-repeated')
                              ? repeatStep.Step_Type
                              : `${repeatStep.Step_Type}-repeated`
                        };
                        newSteps.push(repeatedConfig);
                        if (repeatedConfig.Step_Type === 'Sampling-repeated') {
                           lastSamplingRepeatedIndex = newSteps.length - 1; // Track the index
                        }
                     });
                  }
               }
               repeatBlock = [];
            }
            newSteps.push(step);
         }
      }

      // Further processing for Sampling-Repeated steps...
      if (lastSamplingRepeatedIndex !== -1 && remainingSamplingTimeForLastSamplingRepeatedStep !== 0) {
         newSteps[lastSamplingRepeatedIndex] = {
            ...newSteps[lastSamplingRepeatedIndex],
            Sampling_Pump: {
               ...newSteps[lastSamplingRepeatedIndex].Sampling_Pump,
               t3: remainingSamplingTimeForLastSamplingRepeatedStep
            }
         };
         let samplingTime = newSteps[lastSamplingRepeatedIndex].Sampling_Pump.t3 + newSteps[lastSamplingRepeatedIndex].Sampling_Pump.t1 + 3;
         newSteps[lastSamplingRepeatedIndex].Total_Run_Time = samplingTime;
      }

      // Log the index of Sampling-repeated step if there's exactly one
      let samplingRepeatedCount = 0;
      let samplingRepeatedIndex = -1;
      newSteps.forEach((step, index) => {
         if (step.Step_Type === 'Sampling-repeated') {
            samplingRepeatedCount++;
            samplingRepeatedIndex = index;
         }
      });
      if (samplingRepeatedCount === 1) {
         newSteps[samplingRepeatedIndex].Sampling_Pump.t3 = remainingSamplingTimeForOneRepeatedStep;
         let samplingTime = newSteps[samplingRepeatedIndex].Sampling_Pump.t3 + newSteps[samplingRepeatedIndex].Sampling_Pump.t1 + 3;
         newSteps[samplingRepeatedIndex].Total_Run_Time = samplingTime;
      }

      // Convert newSteps back to modeConfig and update
      newSteps.forEach((step, index) => {
         modeConfig[`Step${index + 1}_Config`] = step;
      });

      // Remove any extra steps
      for (let i = newSteps.length + 1; modeConfig[`Step${i}_Config`]; i++) {
         delete modeConfig[`Step${i}_Config`];
      }

      // Calculate the total sampling time from all Sampling and Sampling-Repeated steps
      newSteps.forEach((step, index) => {
         switch (step.Step_Type) {
            case 'Sampling':
            case 'Sampling-repeated':
               totalSamplingTime += step.Sampling_Pump.t3;
               break;
            case 'Separation':
               separationStepNumber = index + 1;
               break;
         }
      });

      // Update steps based on their stepAction
      let dataUploadNewStepNumber = [];
      let dataUploadStepNumber = [];
      newSteps.forEach((step, index) => {
         switch (step.stepAction) {
            case 'Data Processing':
               step.chroma = separationStepNumber;
               processingStepNumber = index + 1;
               break;

            case 'Data Upload':
               const newStepsResult = this.countDataUploadSteps(newSteps);
               dataUploadNewStepNumber.push(...newStepsResult.steps);

               const originalStepsResult = this.countDataUploadSteps(steps);
               dataUploadStepNumber.push(...originalStepsResult.steps);

               diff = dataUploadNewStepNumber[0] - dataUploadStepNumber[0];
               console.log('diff', diff);
               break;

            case 'Chemical Recognition':
               step.Total_Sampling_Time_Hrs = parseFloat((totalSamplingTime / 3600).toFixed(2));
               step.chroma = separationStepNumber;
               step.processed = processingStepNumber;
               break;
         }
      });

      // Modify uploads using originalUploadSteps and calculated diff
      this.modifyUploadsWithDiff(originalUploadSteps, diff);

      // Update the number of steps
      data.Frac_Delta.Num_of_Step = Object.keys(modeConfig).length;
      data.Frac_Delta.Mode_Config = modeConfig;
   }




   countDataUploadSteps(stepArray) {
      let dataUploadCount = 0; // Reset the count at the beginning
      let dataUploadSteps = []; // To store the correct step numbers

      // Iterate through the keys of the steps array
      stepArray.forEach((stepConfig, index) => {
         // Check if the stepAction is "Data Upload"
         if (stepConfig.stepAction === "Data Upload") {
            // Since stepArray starts at index 0, add 1 to the index to match 1-based step numbering
            let stepNumber = index + 1;
            console.log(`Step Config Number for "Data Upload" at index ${index}: ${stepNumber}`);

            dataUploadCount++; // Increment the count for each Data Upload step
            dataUploadSteps.push(stepNumber); // Add the step number to the array
         }
      });

      // Return the total number of Data Upload steps and the step numbers
      return { count: dataUploadCount, steps: dataUploadSteps };
   }







   modifyUploadsWithDiff(dataUploads, diff) {
      const diffValue = diff;
      // Loop through each upload entry
      dataUploads.forEach(uploadEntry => {
         // Modify each upload step by adding the diff value
         uploadEntry.uploads.forEach(upload => {
            if (upload.step !== null && typeof upload.step === 'number' && !isNaN(upload.step)) {
               // Add the diff to each step
               upload.step = upload.step + diffValue;
            }
         });
      });

      // Log the final result for verification
      console.log("Modified dataUploads:", dataUploads);
   }




   /**
    * submit configuration modal
    */
   submitStep() {
      if (!this.objLoadedParam) {
         this.bIsCorrectSimpleConfiguration = false;
         this.alertMessage(false, 'You have to assign a configuration file.');
         return;
      }

      if (!this.bIsCorrectSimpleConfiguration) {
         return;
      }

      const currentType = this.objLoadedParam['Current_Type'];

      const samplingTime = this.formatSamplingTime();
      const samplingTimeSeconds = samplingTime[0] * 3600 + samplingTime[1] * 60 + samplingTime[2];

      if (!this.isFastModeEnabled) {
         this.objLoadedParam[currentType]['Sampling_Time'] =
            this.strCurrentConfigType === this.arrObjConfigTypes[0]['value'] ? samplingTimeSeconds : 0;
      }

      this.objLoadedParam[currentType]['Idle_Time'] =
         this.strCurrentConfigType === this.arrObjConfigTypes[0]['value'] ? this.settingForm['value']['idleTime'] : 0;

      console.log({
         modalType: this.nModalType,
         data: this.objLoadedParam,
         fileName: this.strLoadedConfigFileName,
         fileKey: this.strSelectedFileKey,
         configrationComment: this.strConfigurationComment,
         estimateLabel: this.strEstimatedTotalTime,
      });
      this.dialogRef.close({
         modalType: this.nModalType,
         data: this.objLoadedParam,
         fileName: this.strLoadedConfigFileName,
         fileKey: this.strSelectedFileKey,
         configrationComment: this.strConfigurationComment,
         estimateLabel: this.strEstimatedTotalTime,
      });
   }

   checkGoAvailability() {
      let bIsGo = false;

      if (this.nCurrentStep < this.nMainStepNumber || this.nCurrentStep >= this.nStepNumber + this.nMainStepNumber) {
         bIsGo = true;
      } else {
         switch (this.strCurrentStepAction) {
            case this.arrStrStepActions[0]:
               // Measurement Run
               if (!this.bIsOverTotalRunTime) {
                  bIsGo = true;
               }
               break;

            case this.arrStrStepActions[1]:
               // Data Processing
               bIsGo = true;
               break;

            case this.arrStrStepActions[2]:
               // Data Upload
               bIsGo = true;
               break;

            case this.arrStrStepActions[3]:
               // Chemical Recognition
               bIsGo = true;
               break;
         }
      }

      return bIsGo;
   }

   /**
    * check if the time is zero
    * @param time of date
    */
   checkDate(time: string) {
      if (time.length === 1) {
         time = '0' + time;
      }
      return time;
   }

   /**
    * convert the firebase timestamp to local date
    * @param timestamp date
    */
   convertToDate(timestamp: any) {
      const date = new Date(timestamp);
      let day = date.getDate() + '';
      let month = date.getMonth() + 1 + '';
      let year = date.getFullYear() + '';
      let hour = date.getHours() + '';
      let minutes = date.getMinutes() + '';
      let seconds = date.getSeconds() + '';

      day = this.checkDate(day);
      month = this.checkDate(month);
      year = this.checkDate(year);
      hour = this.checkDate(hour);
      minutes = this.checkDate(minutes);
      seconds = this.checkDate(seconds);
      return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
   }

   /**
    * update the configuration files by changing the modal and model type selections
    */
   onChangeSetting() {
      this.getConfigList();
   }

   /**
    * clear the configuration list from a cloud
    */
   clearCloudConfigList() {
      this.bIsTryToGetCC = false;
      this.bIsConfigMessage = false;
      this.arrConfigs = [];
      if (this.strCurrentSetupType === this.arrStrSetupTypes[0]) {
         this.getConfigList();
      }
   }

   /**
    * load the configuration data from the firebase
    */
   getConfigList() {
      this.bIsGetCloudConfigs = false;
      this.bIsTryToGetCC = true;

      this._httpService
         .getListByOrder(`${environment.APIS.CONFIGURATIONS}`, 'mode', this.strCurrentDataType, 1)
         .subscribe({
            next: (configs) => {
               this.arrConfigs = [];
               let pos = 0;

               this.arrConfigs = configs
                  .filter(item => {
                     return item.modalType === this.nModalType;
                  })
                  .sort((a, b) => {
                     return parseFloat(b.timestamp) - parseFloat(a.timestamp);
                  })
                  .map(item => {
                     pos++;

                     return {
                        position: pos,
                        name: item['path'].split('/').pop(),
                        url: item['configUrl'],
                        path: item['path'],
                        time: this.convertToDate(item['timestamp']),
                        key: item['key'],
                     };
                  });

               this.bIsGetConfigurations = false;
               this.bIsGetCloudConfigs = true;
               this.initDataTable();
            },
            error: error => {
               console.error('config-modal.component.ts -> getConfigList(): ', error);

               this.arrConfigs = [];
               this.bIsGetConfigurations = false;
               this.bIsGetCloudConfigs = true;
            }
         });
   }

   initDataTable(nCount: number = 0) {
      if (nCount > 50) {
         console.log('Timeout to create the datatable');
      } else if (!this.table || !this.paginator) {
         setTimeout(() => this.initDataTable(nCount), 50);
      } else {
         console.log('Create the datatable', this.arrConfigs);
         // Change this index to the actual index of the time property in each array

         this.arrConfigs.sort((a: any, b: any) => {
            console.log('a:', a);
            console.log('b:', b);

            const timeA = new Date(a.time).getTime() || 0; // Use 0 if parsing fails
            const timeB = new Date(b.time).getTime() || 0; // Use 0 if parsing fails

            // Sort in ascending order based on time
            return timeB - timeA;
         });

         // Create MatTableDataSource with the sorted array
         this.dataSource = new MatTableDataSource(this.arrConfigs);
         this.dataSource.paginator = this.paginator;
      }
   }

   /**
    * build parameters for configuration form
    */
   buildParams() {
      const samplingTime = this.formatSamplingTime();
      const samplingTimeSeconds = samplingTime[0] * 3600 + samplingTime[1] * 60 + samplingTime[2];
      const submitData: any = {
         Mode_Type: this.strCurrentDataType,
         Num_of_Step: this.numberForm['value']['stepNumber'],
         Num_of_Cycle: this.numberForm['value']['runNumber'],
         Sampling_Time: this.strCurrentConfigType === this.arrObjConfigTypes[0]['value'] ? samplingTimeSeconds : 0,
         Idle_Time: this.strCurrentConfigType === this.arrObjConfigTypes[0]['value'] ? this.settingForm['value']['idleTime'] : 0,
         Mode_Config: null,
      };

      const paramData: any = {};
      for (let i = 0; i < this.nStepNumber; i++) {
         const strKeyLabel = `Step${i + 1}_Config`;
         const data = this.arrParams[i];

         // console.log('data: ********* 🦆', data);

         switch (data['stepAction']) {
            case this.arrStrStepActions[0]:
               paramData[strKeyLabel] = {};

               for (const row of this.objTKeyTable['rows']) {
                  paramData[strKeyLabel][row] = {};

                  for (const header of this.objTKeyTable['headers']) {
                     const fieldName = row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1);
                     paramData[strKeyLabel][row][header] = data[fieldName] !== undefined ? data[fieldName] : null;
                  }
               }

               paramData[strKeyLabel]['isCleaning'] = data['isCleaning'];
               paramData[strKeyLabel]['isRepeatedSamplingInRun'] = data['isRepeatedSamplingInRun'];
               paramData[strKeyLabel]['Save_Data'] = data['saveData'];
               paramData[strKeyLabel]['Amp_Enable'] = data['ampEnable'];
               paramData[strKeyLabel]['Max_Sampling_Time_Per_Step'] = data['maxSamplingTimePerStep'] !== undefined ? data['maxSamplingTimePerStep'] : 0;  // Ensure valid value
               paramData[strKeyLabel]['Total_Run_Time'] = data['totalRuntime'];
               paramData[strKeyLabel]['Step_Type'] = data['stepType'];

               if (this.nModalType === this.arrIntModalTypes[1] || this.nModalType === this.arrIntModalTypes[2]) {
                  // omni-2000, omni-2100
                  paramData[strKeyLabel]['Target_Board_Temp'] = data['targetBoardTemp'];

                  paramData[strKeyLabel]['Board_Temp_Control'] = data['boardTempControl'];
                  paramData[strKeyLabel]['Fan_Control'] = data['fanControl'];

                  if (this.nModalType === this.arrIntModalTypes[2]) {
                     // omni-2100
                     for (let rowIndex = 0; rowIndex < this.objValuePumpKeyTable['rows'].length; rowIndex++) {
                        let row = this.objValuePumpKeyTable['rows'][rowIndex];
                        row = row.split(' ').join('_');
                        paramData[strKeyLabel][row] = {};

                        for (let headerIndex = 0; headerIndex < this.objValuePumpKeyTable['headers'].length; headerIndex++) {
                           const header = this.objValuePumpKeyTable['headers'][headerIndex];
                           const fieldName = row + '_' + header;

                           if (rowIndex >= 0 && rowIndex < 5 && headerIndex >= 0 && headerIndex < 2) {
                              paramData[strKeyLabel][row][header] = data[fieldName] !== undefined
                                 ? data[fieldName]
                                 : global_variables['onOffStatus'][1];
                           } else if (headerIndex === 3 && rowIndex >= 0 && rowIndex < 5) {
                              paramData[strKeyLabel][row][header] = data[fieldName] !== undefined ? data[fieldName] : 'N/A';
                           } else {
                              paramData[strKeyLabel][row][header] = data[fieldName] !== undefined ? data[fieldName] : 0;
                           }
                        }
                     }
                  }
               }
               break;

            case this.arrStrStepActions[1]:
               paramData[strKeyLabel] = {
                  algorithm: data['algorithm'],
                  chroma: data['chroma'],
               };
               if (this.nModalType === this.arrIntModalTypes[0]) {
                  paramData[strKeyLabel]['calib'] = data['calib'];
               }
               break;

            case this.arrStrStepActions[2]:
               paramData[strKeyLabel] = {
                  uploads: data['uploads'],
                  isCleaning: data['isCleaning'],
               };
               break;

            case this.arrStrStepActions[3]:
               paramData[strKeyLabel] = {
                  chroma: data['chroma'],
                  processed: data['processed'],
                  start: data['start'],
                  total: data['total'],
               };
               if (this.nModalType === this.arrIntModalTypes[0]) {
                  paramData[strKeyLabel]['calib'] = data['calib'];
                  paramData[strKeyLabel]['enabled'] = data['enabled'] !== undefined ? data['enabled'] : false;
               }
               break;
         }

         paramData[strKeyLabel]['stepAction'] = data['stepAction'];
      }

      submitData['Mode_Config'] = paramData;
      const configData: any = {};
      configData[this.strCurrentDataType] = submitData;
      configData['Current_Type'] = this.strCurrentDataType;

      return configData;
   }


   /**
    * submit configuration parameters
    */
   submitParams() {
      const configData = this.buildParams();
      this.dialogRef.close({
         modalType: this.nModalType,
         data: configData,
         fileName:
            this.strLoadedConfigFileName && JSON.stringify(configData) === JSON.stringify(this.objLoadedParam)
               ? this.strLoadedConfigFileName
               : '',
      });
   }

   /**
    * initialize form
    * @param isCreate boolean if it is form creation
    * @param params pre-data
    */
   initForm(isCreate: boolean = true, params: Object = null) {
      const group: any = {};
      this.objTKeyTable = <any>{};
      this.objValuePumpKeyTable = <any>{};

      switch (this.strCurrentDataType) {
         case this.arrStrDataTypes[0]: // Frac Delta
            this.objTKeyTable = {
               headers: ['tIdle', 'tTarget', 't1', 't2', 't3'],
               rows: ['KP1', 'KP3', 'PCF', 'Injector', 'Column1', 'Column2', 'Column3'],
            };
            break;
         case this.arrStrDataTypes[1]: // Absolute
            this.objTKeyTable = {
               headers: ['tIdle', 'tTarget', 't1', 't2', 't3'],
               rows: ['KP1', 'KP2', 'KP3', 'KP4', 'PCF', 'Injector', 'Column1', 'Column2', 'Column3'],
            };
            break;
         case this.arrStrDataTypes[2]: // Delta
            this.objTKeyTable = {
               headers: ['tIdle', 'tTarget', 't1', 't2', 't3'],
               rows: ['KP1', 'KP3', 'PCF', 'Injector', 'Column1', 'Column2', 'Column3'],
            };
            break;
      }

      if (this.nModalType === this.arrIntModalTypes[2]) {
         // omni-2100
         this.objTKeyTable['rows'].splice(0, 2);
         this.objTKeyTable['rows'].push('NafionTube');
      }

      this.objValuePumpKeyTable = {
         headers: ['idleValue', 'targetValue', 't1', 't2', 't3'],
         rows: [
            'Valve1',
            'Valve2',
            'Valve3',
            'Sampling Pump',
            'Separation Pump Power',
            'Separation Pump Amplitude',
            'Separation Pump Frequency',
         ],
      };

      if (isCreate) {
         for (const row of this.objTKeyTable['rows']) {
            let min = 0;
            let max = 0;
            let isDisabled = false;

            if (row === 'PCF') {
               max = 300;
            } else if (row.indexOf('NafionTube') === 0) {
               max = 75;
            } else if (row.indexOf('KP') === 0) {
               max = 90;
            } else {
               max = 50;
            }

            if (row === 'Injector' || row === 'KP3') {
               isDisabled = true;
            }

            for (const header of this.objTKeyTable['headers']) {
               const ts = ['t1', 't2', 't3'];

               if (ts.indexOf(header) >= 0) {
                  min = 0;
                  max = 14400;
               } else if (row === 'KP1') {
                  min = -40;
                  max = 40;
               }

               const fieldName = row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1);

               const initValue = row === 'NafionTube' ? 0 : 1;

               group[fieldName] = new FormControl(initValue, [
                  <any>Validators.required,
                  Validators.min(min),
                  Validators.max(max),
               ]);

               if (isDisabled) {
                  group[fieldName].disable();
               }
            }
         }

         group['saveData'] = new FormControl(false, [<any>Validators.required]);
         group['ampEnable'] = new FormControl(false, [<any>Validators.required]);
         group['totalRuntime'] = new FormControl(0, [<any>Validators.required]);
         group['stepType'] = new FormControl(this.arrStrStepTypes[0], [<any>Validators.required]);
         group['maxSamplingTimePerStep'] = new FormControl({ value: 0, disabled: true }, []);  // Initialize as disabled with no validators

         if (this.nModalType === this.arrIntModalTypes[1] || this.nModalType === this.arrIntModalTypes[2]) {
            // omni-2000, omni-2100
            group['targetBoardTemp'] = new FormControl(0, [<any>Validators.required]);
            group['boardTempControl'] = new FormControl(0, [<any>Validators.required]);
            group['fanControl'] = new FormControl(0, [<any>Validators.required]);

            if (this.nModalType === this.arrIntModalTypes[2]) {
               // omni-2100
               group['isCleaning'] = new FormControl(0, [<any>Validators.required]);
               group['isRepeatedSamplingInRun'] = new FormControl(0, [<any>Validators.required]);
               for (let rowIndex = 0; rowIndex < this.objValuePumpKeyTable['rows'].length; rowIndex++) {
                  let row = this.objValuePumpKeyTable['rows'][rowIndex];
                  row = row.split(' ').join('_');

                  for (let headerIndex = 0; headerIndex < this.objValuePumpKeyTable['headers'].length; headerIndex++) {
                     const header = this.objValuePumpKeyTable['headers'][headerIndex];
                     const fieldName = row + '_' + header;

                     if (rowIndex >= 0 && rowIndex < 5 && headerIndex >= 0 && headerIndex < 2) {
                        group[fieldName] = new FormControl(global_variables['onOffStatus'][1], [
                           <any>Validators.required,
                        ]);
                     } else if (rowIndex === 5 && headerIndex >= 0 && headerIndex < 2) {
                        group[fieldName] = new FormControl(0, [
                           <any>Validators.required,
                           Validators.min(0),
                           Validators.max(1.3),
                        ]);
                     } else if (rowIndex === 6 && headerIndex >= 0 && headerIndex < 2) {
                        group[fieldName] = new FormControl(0, [
                           <any>Validators.required,
                           Validators.min(0),
                           Validators.max(1000),
                        ]);
                     } else if (headerIndex === 2 || headerIndex === 4) {
                        group[fieldName] = new FormControl(0, [
                           <any>Validators.required,
                           Validators.min(0),
                           Validators.max(14400),
                        ]);
                     } else if ((rowIndex === 5 || rowIndex === 6) && headerIndex === 3) {
                        group[fieldName] = new FormControl(0, [
                           <any>Validators.required,
                           Validators.min(0),
                           Validators.max(14400),
                        ]);
                     } else {
                        group[fieldName] = new FormControl('N/A');
                        group[fieldName].disable();
                     }
                  }
               }
            }
         }

         this.configForm = new FormGroup(group);
         this.objConfigFormErrors = <any>{};

         for (const key in group) {
            if (group.hasOwnProperty(key)) {
               this.objConfigFormErrors[key] = '';
            }
         }

         if (this.configFormSub) {
            this.configFormSub.unsubscribe();
         }

         // Initial check for stepType to enable/disable maxSamplingTimePerStep
         if (group['stepType'].value === 'Sampling') {
            this.setMaxSamplingTimePerStepState(true);
         } else {
            this.setMaxSamplingTimePerStepState(false);
         }

         this.configFormSub = this.configForm.valueChanges.pipe(debounceTime(300)).subscribe({
            next: (data) => {
               this.handleFormValueChanges(data);
               this.onValueChanged();
            },
            error: error => {
               console.error('config-modal.component.ts -> initSettingForm(): ', error);
            }
         });

         // Initialize the number form
         const maxRuns = this.objSelectedSensor.maxRuns;
         this.numberForm = new FormGroup({
            stepNumber: new FormControl(1, [<any>Validators.required, Validators.min(1), Validators.max(100)]),
            runNumber: new FormControl(1, [
               <any>Validators.required,
               Validators.min(1),
               Validators.max(maxRuns || 999),
            ]),
         });

         this.bIsLoadForm = true;
      } else {
         for (const row of this.objTKeyTable['rows']) {
            for (const header of this.objTKeyTable['headers']) {
               const fieldName = row.toLowerCase() + header.charAt(0).toUpperCase() + header.slice(1);
               if (params && params[row]) {
                  this.configForm.controls[fieldName].setValue(params[row][header]);
               } else {
                  this.configForm.controls[fieldName].setValue(1);
               }
            }
         }

         if (params) {
            this.configForm.controls['isCleaning'].setValue(params['isCleaning']);
            this.configForm.controls['isRepeatedSamplingInRun'].setValue(params['isRepeatedSamplingInRun']);
            this.configForm.controls['saveData'].setValue(params['Save_Data']);
            this.configForm.controls['ampEnable'].setValue(params['Amp_Enable']);
            this.configForm.controls['totalRuntime'].setValue(params['Total_Run_Time']);
            this.configForm.controls['stepType'].setValue(params['Step_Type']);
            this.configForm.controls['maxSamplingTimePerStep'].setValue(params['Max_Sampling_Time_Per_Step']);

         } else {
            this.configForm.controls['isCleaning'].setValue(false);
            this.configForm.controls['isRepeatedSamplingInRun'].setValue(false);
            this.configForm.controls['saveData'].setValue(false);
            this.configForm.controls['ampEnable'].setValue(false);
            this.configForm.controls['totalRuntime'].setValue(0);
            this.configForm.controls['stepType'].setValue(this.arrStrStepTypes[0]);
         }

         if (this.nModalType === this.arrIntModalTypes[1] || this.nModalType === this.arrIntModalTypes[2]) {
            // omni-2000, omni-2100
            if (params) {
               this.configForm.controls['targetBoardTemp'].setValue(params['Target_Board_Temp']);
               this.configForm.controls['isRepeatedSamplingInRun'].setValue(
                  params && params['isRepeatedSamplingInRun'] !== undefined ? params['isRepeatedSamplingInRun'] : false
               );

               this.configForm.controls['boardTempControl'].setValue(params['Board_Temp_Control']);
               this.configForm.controls['fanControl'].setValue(params['Fan_Control']);
            } else {
               this.configForm.controls['targetBoardTemp'].setValue(0);
               this.configForm.controls['maxSamplingTimePerStep'].setValue(0);
               this.configForm.controls['boardTempControl'].setValue(0);
               this.configForm.controls['fanControl'].setValue(0);
            }

            if (this.nModalType === this.arrIntModalTypes[2]) {
               // omni-2100
               for (let rowIndex = 0; rowIndex < this.objValuePumpKeyTable['rows'].length; rowIndex++) {
                  let row = this.objValuePumpKeyTable['rows'][rowIndex];
                  row = row.split(' ').join('_');

                  for (let headerIndex = 0; headerIndex < this.objValuePumpKeyTable['headers'].length; headerIndex++) {
                     const header = this.objValuePumpKeyTable['headers'][headerIndex];
                     const fieldName = row + '_' + header;

                     if (params && params[row]) {
                        this.configForm.controls[fieldName].setValue(params[row][header]);
                     } else {
                        let defaultValue: any = 0;
                        if (rowIndex >= 0 && rowIndex < 5 && headerIndex >= 0 && headerIndex < 2) {
                           defaultValue = global_variables['onOffStatus'][1];
                        } else if (headerIndex === 3 && rowIndex >= 0 && rowIndex < 5) {
                           defaultValue = 'N/A';
                        } else {
                           defaultValue = 0;
                        }

                        this.configForm.controls[fieldName].setValue(defaultValue);
                     }
                  }
               }
            }
         }
      }
   }




   handleFormValueChanges(data: any) {
      if (data['stepType'] === 'Sampling') {
         this.setMaxSamplingTimePerStepState(true);
      } else {
         this.setMaxSamplingTimePerStepState(false);
      }
   }


   checkConfigFormFields() {
      if (!this.configForm) {
         return;
      }

      const form = this.configForm;

      // Add condition to enable/disable maxSamplingTimePerStep based on stepType
      const stepTypeControl = form.get('stepType');
      const maxSamplingTimePerStepControl = form.get('maxSamplingTimePerStep');

      if (stepTypeControl && maxSamplingTimePerStepControl) {
         if (stepTypeControl.value === 'Sampling') {
            this.setMaxSamplingTimePerStepState(true);
         } else {
            this.setMaxSamplingTimePerStepState(false);
         }
      }

      for (const field in this.objConfigFormErrors) {
         if (this.objConfigFormErrors.hasOwnProperty(field)) {
            this.objConfigFormErrors[field] = '';
            const control = form.get(field);

            if (control.hasError('required')) {
               this.objConfigFormErrors[field] = 'Required field';
               this.configForm.controls[field].markAsTouched();
            } else if (control.hasError('min') || control.hasError('max')) {
               this.objConfigFormErrors[field] = 'Out of range';
               this.configForm.controls[field].markAsTouched();
            }
         }
      }
   }
   private setMaxSamplingTimePerStepState(enable: boolean) {
      const control = this.configForm.get('maxSamplingTimePerStep');
      if (control) {
         if (enable) {
            control.setValidators([<any>Validators.required]); // Re-add the validators
            control.enable(); // Enable the control
         } else {
            control.clearValidators(); // Remove all validators
            control.setValue(0); // Optionally set it to a safe value, e.g., null or 0
            control.disable(); // Disable the control
         }
         control.updateValueAndValidity(); // Update the control's state
      }
   }

   onValueChanged(data?: any) {
      if (this.bIsLoadForm) {
         this.checkTotalRunTime();
      }

      this.checkConfigFormFields();
   }

   setFormParam(params: Object) {
      for (const key in this.configForm.controls) {
         if (this.configForm.controls.hasOwnProperty(key)) {
            this.configForm.controls[key].setValue(params[key]);
         }
      }
   }

   ngOnInit() {
      this.getAssignedConfiguration()
         .then(() => {
            this.bIsGetAssignedCongiguration = true;
            this.nCurrentStep = 0;

            if (this.bIsAssignMode) {
               this.strCurrentConfigType = this.arrObjConfigTypes[1]['value'];
               this.nextStep();
            }

            if (this._authService.isUserEmailLoggedIn && this._authService.currentUserId) {
               this.strUserId = this._authService.currentUserId;
               if (this._authService.isUserStaff) {
                  // staff
                  this.strCurrentUserType = this.arrStrUserTypes[0];
               } else {
                  // customer
                  this.strCurrentUserType = this.arrStrUserTypes[1];

                  if (!this.bIsAssignMode) {
                     this.strCurrentConfigType = this.arrObjConfigTypes[0]['value'];
                     this.nextStep();
                  }
               }
            } else {
               this._snackBar.open('You need to login with proper permission.', 'Notification', {
                  duration: 3000,
                  verticalPosition: 'top',
                  horizontalPosition: 'center',
               });
               this._router.navigate(['/dashboard']);
            }
         })
         .catch(() => {
            this.bIsGetAssignedCongiguration = true;
         });

   }

   ngOnDestroy() {
      this.destroy$.next(null);
      this.destroy$.complete();

      if (this.configFormSub) {
         this.configFormSub.unsubscribe();
      }

      if (this.sensorDevicesSub) {
         this.sensorDevicesSub.unsubscribe();
      }

      if (this.settingFormSub) {
         this.settingFormSub.unsubscribe();
      }
   }

   initSettingForm() {
      this.arrIntSamplingHours = [...Array(50).keys()];
      this.arrIntSamplingMinutes = [...Array(61).keys()];

      this.arrIntSamplingSeconds = [...Array(61).keys()].filter(second => second % 5 === 0);

      this.arrObjSamplingTimes = [];

      if (this.objConfigData && this.objConfigData['Current_Modal_Type']) {
         this.nModalType = this.nAssignedModalType ? this.nAssignedModalType : this.objConfigData['Current_Modal_Type'];

         if (this.nModalType === this.arrIntModalTypes[1]) {
            // omni-2000
            this.arrObjSamplingTimes = [
               {
                  label: '00:30',
                  value: 30,
               },
               {
                  label: '00:45',
                  value: 45,
               },
               {
                  label: '1:00',
                  value: 60,
               },
               {
                  label: '1:15',
                  value: 75,
               },
               {
                  label: '1:30',
                  value: 90,
               },
               {
                  label: '1:45',
                  value: 105,
               },
               {
                  label: '2:00',
                  value: 120,
               },
               {
                  label: '2:15',
                  value: 135,
               },
               {
                  label: '2:30',
                  value: 150,
               },
               {
                  label: '2:45',
                  value: 165,
               },
               {
                  label: '3:00',
                  value: 180,
               },
               {
                  label: '3:15',
                  value: 195,
               },
               {
                  label: '3:30',
                  value: 210,
               },
               {
                  label: '3:45',
                  value: 225,
               },
               {
                  label: '4:00',
                  value: 240,
               },
            ];

            if (this.settingForm) {
               this.onChangeSettingForm();
               return;
            }

            const maxRuns = this.objSelectedSensor.maxRuns;

            this.settingForm = new FormGroup({
               runNumber: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(maxRuns || 999)]),
               samplingTime: new FormControl(30, [Validators.required]),
               idleTime: new FormControl(300, [Validators.required, Validators.min(1)]),
            });
         } else if (this.nModalType === this.arrIntModalTypes[2]) {
            // omni-2100
            // this.arrIntSamplingHours = [0, 1];
            // this.arrIntSamplingMinutes = [];
            // this.arrIntSamplingSeconds = [];
            // for (let index = 0; index < 60; index++) {
            //    this.arrIntSamplingMinutes.push(index);
            //    this.arrIntSamplingSeconds.push(index);
            // }

            if (this.settingForm) {
               this.onChangeSettingForm();
               return;
            }
            const maxRuns = this.objSelectedSensor.maxRuns;
            const fastMode = this.objSelectedSensor['fastMode'];
            const samplingTimeSecs = this.objSelectedSensor['fastModeSamplingTime'];

            this.settingForm = new FormGroup({
               runNumber: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(maxRuns || 999)]),
               samplingTimeHours: new FormControl(0, [Validators.required]),
               samplingTimeMins: new FormControl(0, [Validators.required]),
               samplingTimeSecs: new FormControl((fastMode && samplingTimeSecs) || 0, [Validators.required]),
               idleTime: new FormControl(fastMode ? 0 : 300, [Validators.required, Validators.min(fastMode ? 0 : 1)]),
            });

            this.isFastModeEnabled = !!fastMode;
         }

         this.objLoadedParam = this.objAssignedData;

         if (this.settingForm) {
            this.onChangeSettingForm();
            this.settingFormSub = this.settingForm.valueChanges.subscribe({
               next: (data) => this.onChangeSettingForm(data),
               error: error => console.error('config-modal.component.ts -> initSettingForm(): ', error)
            });
         }
      }
   }

   /**
    * convert seconds to hh, mm, seconds
    * @param secs: seconds
    */
   convertSeconds(secs: number) {
      const days = Math.floor(Math.floor(secs / 3600) / 24);
      const hours = Math.floor(secs / 3600) % 24;
      const minutes = Math.floor(secs / 60) % 60;
      const seconds = secs % 60;
      return [days, hours, minutes, seconds];
   }

   /**
    * format time to display
    * @param time: hour, minute, second
    */
   formatTime(time: number) {
      return time < 10 ? '0' + time : time;
   }

   /**
    * format sampling time
    */
   formatSamplingTime() {
      let hours = 0;
      let minutes = 0;
      let seconds = 0;

      if (this.strCurrentConfigType === this.arrObjConfigTypes[0]['value']) {
         if (this.nModalType === this.arrIntModalTypes[1]) {
            // omni-2000
            hours = Math.floor(this.settingForm['value']['samplingTime'] / 60);
            minutes = this.settingForm['value']['samplingTime'] % 60;
         } else if (this.nModalType === this.arrIntModalTypes[2]) {
            // omni-2100
            hours = this.settingForm['value']['samplingTimeHours'] ? this.settingForm['value']['samplingTimeHours'] : 0;
            minutes = this.settingForm['value']['samplingTimeMins'] ? this.settingForm['value']['samplingTimeMins'] : 0;
            seconds = this.settingForm['value']['samplingTimeSecs'] ? this.settingForm['value']['samplingTimeSecs'] : 0;
         }
         console.log(this.objLoadedParam, 'this.objLoadedParam from formatSamplingTime');
      }

      return [hours, minutes, seconds];
   }

   onChangeSettingForm(data?: any) {
      if (!this.settingForm || !this.objAssignedData) {
         this.bIsConfigMessage = true;
         this.bIsCorrectSimpleConfiguration = false;
         this.alertMessage(false, 'You have to assign a configuration file.');
         return;
      }

      if (this.settingForm.invalid) {
         return;
      }

      const runNumber = this.settingForm['value']['runNumber'];
      const idleTime = this.settingForm['value']['idleTime'];
      const simpleConfigurationData = this.checkSimpleConfiguration();

      if (!simpleConfigurationData['correct']) {
         return;
      }

      this.bIsConfigMessage = true;
      this.strLoadedConfigFileName = this.strAssignedConfigurationName ? this.strAssignedConfigurationName : '';

      const samplingTimeMinutes = Math.floor(simpleConfigurationData.samplingTimeSeconds / 60);
      const idleTimeMinutes = Math.floor(idleTime / 60);
      const processingTimeMinutes = Math.floor(simpleConfigurationData.otherSum / 60);

      let totalMinutes = 0;

      if (this.objSelectedSensor.fastMode) {
         // FastMode Calculation
         totalMinutes = runNumber * (samplingTimeMinutes + idleTimeMinutes + processingTimeMinutes);
      } else {
         // Normal Mode Calculation
         const totalSamplingTimeMinutes = runNumber * samplingTimeMinutes;
         const totalIdleTimeMinutes = runNumber * idleTimeMinutes;
         const totalProcessingTimeMinutes = runNumber * processingTimeMinutes;

         totalMinutes = totalSamplingTimeMinutes + totalIdleTimeMinutes + totalProcessingTimeMinutes;
      }

      // Convert total time to days, hours, and minutes
      const totalDays = Math.floor(totalMinutes / 1440);
      const totalHours = Math.floor((totalMinutes % 1440) / 60);
      const totalMinutesRemaining = totalMinutes % 60;

      this.strEstimatedTotalTime = `${totalDays} day${totalDays !== 1 ? 's' : ''} ${totalHours} hour${totalHours !== 1 ? 's' : ''} ${totalMinutesRemaining} minute${totalMinutesRemaining !== 1 ? 's' : ''}, `;

      this.strEstimatedTotalTime += `based on ${runNumber} ${runNumber > 1 ? 'runs' : 'run'}, `;
      this.strEstimatedTotalTime += `each configured with ${samplingTimeMinutes} minute${samplingTimeMinutes !== 1 ? 's' : ''} of sampling `;
      this.strEstimatedTotalTime += `(Step ${simpleConfigurationData['samplingStep']}), `;
      this.strEstimatedTotalTime += `${idleTimeMinutes} minute${idleTimeMinutes !== 1 ? 's' : ''} `;
      this.strEstimatedTotalTime += `for the idle interval (Step ${simpleConfigurationData['idleStep']}) and `;
      this.strEstimatedTotalTime += `${processingTimeMinutes} minute${processingTimeMinutes !== 1 ? 's' : ''} of required processing (all other steps).`;

      this.updateStrEstimatedTotalTime(this.strEstimatedTotalTime);
   }



   formatTimeFromSeconds(seconds: number): string {
      const hours = Math.floor(seconds / 3600);
      const remainingSecondsAfterHours = seconds % 3600;
      const minutes = Math.floor(remainingSecondsAfterHours / 60);
      const remainingSeconds = remainingSecondsAfterHours % 60;

      let timeString = '';

      if (hours > 0) {
         timeString += `${hours} hour${hours > 1 ? 's' : ''}`;
      }

      if (minutes > 0) {
         if (timeString !== '') {
            timeString += ` ${minutes} minute${minutes > 1 ? 's' : ''}`;
         } else {
            timeString += `${minutes} minute${minutes > 1 ? 's' : ''}`;
         }
      }

      if (remainingSeconds > 0) {
         if (timeString !== '') {
            timeString += ` ${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
         } else {
            timeString += `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
         }
      }

      return timeString;
   }

   convertToSeconds(totalSeconds: number) {
      const days = Math.floor(totalSeconds / (3600 * 24));
      totalSeconds %= 3600 * 24;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      return { days, hours, minutes, seconds };
   }

   close() {
      this.dialogRef.close();
   }

   /**
    * download configuration parameter as a json file
    */
   downloadParam() {
      this.bIsAlert = false;

      if (!this.strConfigFileName) {
         this.bIsAlert = true;
         this.bIsSaveConfiguration = false;
         this.nAlertType = 2;
      } else {
         const configData = this.buildParams();
         const theJSON = JSON.stringify(configData);
         // this.downloadLink = this._sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
         const downloadHandler = document.createElement('a');
         downloadHandler.href = 'data:text/json;charset=UTF-8,' + encodeURIComponent(theJSON);
         downloadHandler.download = `${this.strConfigFileName}.json`;
         downloadHandler.click();
      }
   }

   /**
    * update customer poartal
    * @param strUrl of configuration
    * @param path of configuration storage
    */
   updateCustomerPortal(strUrl: string, path: string) {
      if (this.strUserId) {
         const postValue = {
            configUrl: strUrl,
            mode: this.strCurrentDataType,
            path: path,
            // timestamp: database.ServerValue.TIMESTAMP,
            timestamp: serverTimestamp(),
            modalType: this.nModalType,
         };

         this._httpService.createAsList(`${environment.APIS.CONFIGURATIONS}`, postValue).then(
            () => {
               this.bIsSaveConfiguration = false;
               this.bIsAlert = true;
               this.nAlertType = 0;
               this.bIsUploadConfigurationToCloud = true;
            },
            error => {
               this.bIsSaveConfiguration = false;
               console.error(error);
               this.bIsAlert = true;
               this.nAlertType = 1;
            }
         );
      } else {
         console.log('User uid is not existed');
      }
   }

   uploadParamsToCloud() {
      this.bIsAlert = false;
      if (!this.strConfigFileName) {
         this.bIsAlert = true;
         this.bIsSaveConfiguration = false;
         this.nAlertType = 2;
      } else {
         this.bIsSaveConfiguration = true;
         const configData = this.buildParams();
         const theJSON = JSON.stringify(configData);
         const blob = new Blob([theJSON], { type: 'application/json' });

         const fileFolder = environment.Upload.SENSORCOMFIGS;
         const timestamp = new Date().getTime().toString();
         const filename = `${this.strConfigFileName}_${timestamp}`;
         const path = `/${fileFolder}/${this.objSelectedSensor['customerId']}/${this.objSelectedSensor['zoneId']}/${filename}`;

         const task = this.storage.upload(path, blob);
         console.log('Uploaded a blob or file! Now storing the reference at', path);
         task
            .snapshotChanges()
            .pipe(
               finalize(() => {
                  const downloadUrl = this.storage.ref(path).getDownloadURL();
                  downloadUrl.subscribe({
                     next: (url) => {
                        this.updateCustomerPortal(url, path);
                     },
                     error: error => console.error('config-modal.component.ts -> uploadParamsToCloud(): ', error)
                  });
               })
            )
            .subscribe();
      }
   }

   onImport(event: any) {
      const file = event.target.files[0];

      if (file) {
         const reader = new FileReader();
         reader.readAsText(file, 'UTF-8');
         const that = this;

         reader.onload = evt => {
            that.objLoadedParam = JSON.parse(evt.target['result'] as any);
            if (that.objLoadedParam.hasOwnProperty(that.strCurrentDataType)) {
               that.bIsImportFile = true;
               that.bIsConfigMessage = true;
               that.alertMessage(true, 'This configuration must have a sampling and a idle step.');
            } else {
               that.bIsConfigMessage = true;
               that.alertMessage(false, 'This configuration must have a sampling and a idle step.');
            }
         };

         reader.onerror = evt => {
            console.log('error reading file');
         };
      }
   }

   downloadJSONAction(data: any, strName: string) {
      const blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', strName);
      link.click();
      //  TODO add remove
      //  TODO window.URL.revokeObjectURL(url);
      return link;
   }

   onDownloadJSONFile(strUri: string, strName: string) {
      const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud
      // let strFireFunctionUrl = environment.VARIABLES.FirebaseFunctionUrlLocal; // local

      const objPostData = {
         url: strUri,
      };

      this._purehttpService.callFirebaseFunction(`${strFireFunctionUrl}/getData`, objPostData).subscribe({
         next: (res: any) => {
            const data = res.data;
            if (strName.split('.').pop() !== 'json' || strName === 'json') {
               strName += '.json';
            }

            this.downloadJSONAction(data, strName);
         },
         error: error => {
            console.log('Fail to get the data to download.');
            console.error('config-modal.component.ts -> onDownloadJSONFile(): ', error);
         }
      });
   }

   /**
    * delete configuration file
    * @param strConfigUrl configuration storage
    * @param strKey storage key
    */
   onDeleteConfig(strConfigUrl: string, strKey: string) {
      const config = {
         disableClose: true,
         maxWidth: '400px',
         data: {
            title: 'Delete',
            description: 'Are you sure to delete sensor configuration data?',
         },
      };

      const dialogRefConfirm = this.dialog.open(ConfirmModalComponent, config);

      if (this.dialogConfirmSub) {
         this.dialogConfirmSub.unsubscribe();
      }

      this.dialogConfirmSub = dialogRefConfirm
         .afterClosed()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (dialogResult) => {
               if (dialogResult) {
                  this.bIsGetConfigurations = true;
                  this.storage
                     .ref(strConfigUrl)
                     .delete()
                     .pipe(take(1))
                     .subscribe({
                        next: () => {
                           // File deleted successfully
                           console.log('Deleted the csv file Successfully.');
                        },
                        error: err => {
                           // Uh-oh, an error occurred!
                           console.log('Deleting csv error: ');
                           console.error('config-modal.component.ts -> onDeleteConfig(): ', err);
                        }
                     });

                  const strConfigDBUrl = `${environment.APIS.CONFIGURATIONS}/${strKey}`;

                  this._httpService.deleteAsObject(strConfigDBUrl).then(
                     () => {
                        console.log('Delete successfully: ' + strConfigDBUrl);
                        this.strSelectedFileKey = '';
                        this.getConfigList();
                     },
                     error => {
                        console.error('config-modal.component.ts -> onDeleteConfig(): ', error);
                     }
                  );
               }
            },
            error: error => {
               console.error('config-modal.component.ts -> onDeleteConfig(): ', error);
            }
         });
   }

   alertMessage(isSuccess: boolean, alert = '') {
      this.bIsAlertSuccess = isSuccess;
      this.strConfigurationAlert = alert;
   }

   /**
    * load the configuration file from the firebase
    * @param objData of configuration
    */
   onLoadConfig(objData: Object) {
      const strLoadUrl = objData['url'];
      this.bIsConfigMessage = false;
      this.strLoadedConfigFileName = objData['name'];
      this.strSelectedFileKey = objData['key'];

      if (strLoadUrl) {
         this.bIsLoadConfiguration = true;
         const objPostData = {
            url: strLoadUrl,
         };

         const strFireFunctionUrl = environment['FirebaseFunctionUrlCloud']; // cloud
         // let strFireFunctionUrl = environment.VARIABLES.FirebaseFunctionUrlLocal; // local

         this._purehttpService.callFirebaseFunction(`${strFireFunctionUrl}/getData`, objPostData).subscribe({
            next: (res: any) => {
               const data = this.configData.fillDefaultConfigProps(res.data);
               this.configData.missedProps.forEach(prop => {
                  this._notification.createNotification(
                     'info',
                     prop,
                     'added missing property from config template to using config',
                     { timeout: 15000 }
                  );
               });
               for (let i = 0; i < this.arrStrDataTypes.length; i++) {
                  const strTypeKey = this.arrStrDataTypes[i];
                  if (data.hasOwnProperty(strTypeKey) && data['Current_Type'] === strTypeKey) {
                     this.objLoadedParam = data;
                     this.bIsLoadConfiguration = false;
                     this.bIsConfigMessage = true;
                     this.bIsImportFile = true;
                     this.checkSimpleConfiguration();
                     this.initDataTable();
                     break;
                  }
               }
            },
            error: error => {
               console.log('Fail to getting the file data.');
               console.error('config-modal.component.ts -> onLoadConfig(): ', error);
            }
         });
      } else {
         console.log('The Url is not defined');
      }
   }

   onChangeConfigType() {
      this.bIsImportFile = false;
      this.clearCloudConfigList();
   }

   onAddUploadData() {
      if (this.arrObjUploadOptions.length > 0) {
         this.arrObjUploadData.push({
            step: this.arrObjUploadOptions[0]['value'],
         });
      }
   }

   onAddStep() {
      if (this.nCurrentStep >= this.nMainStepNumber) {
         if (
            (this.configForm && !this.configForm.valid) ||
            (this.bIsOverTotalRunTime && this.strCurrentStepAction === this.arrStrStepActions[0])
         ) {
            this._snackBar.open('At a moment, you are in invalid form', 'Error', {
               duration: 3000,
               verticalPosition: 'top',
               horizontalPosition: 'center',
            });
            return;
         }
         const data = <any>{};

         for (const row of this.objTKeyTable['rows']) {
            data[row] = <any>{};

            for (const header of this.objTKeyTable['headers']) {
               data[row][header] = 1;
            }
         }

         data['isCleaning'] = false;
         data['isRepeatedSamplingInRun'] = false;
         data['Save_Data'] = false;
         data['Amp_Enable'] = false;
         data['Total_Run_Time'] = 0;
         data['Step_Type'] = this.arrStrStepTypes[0];

         if (this.nModalType === this.arrIntModalTypes[1] || this.nModalType === this.arrIntModalTypes[2]) {
            // omni-2000, omni-2100
            data['Target_Board_Temp'] = 0;
            data['Max_Sampling_Time_Per_Step'] = 0;
            data['Target_Board_Temp_Range'] = 0;
            data['Control_Cycle_Period'] = 0;
            data['Board_Temp_Control'] = this.arrStrStepTypes[0];
            data['Fan_Control'] = this.arrStrStepTypes[0];

            if (this.nModalType === this.arrIntModalTypes[2]) {
               // omni-2100
               for (let rowIndex = 0; rowIndex < this.objValuePumpKeyTable['rows'].length; rowIndex++) {
                  let row = this.objValuePumpKeyTable['rows'][rowIndex];
                  row = row.split(' ').join('_');
                  data[row] = <any>{};

                  for (let headerIndex = 0; headerIndex < this.objValuePumpKeyTable['headers'].length; headerIndex++) {
                     const header = this.objValuePumpKeyTable['headers'][headerIndex];

                     let defaultValue: any = 0;
                     if (rowIndex >= 0 && rowIndex < 5 && headerIndex >= 0 && headerIndex < 2) {
                        defaultValue = global_variables['onOffStatus'][1];
                     } else if (headerIndex === 3 && rowIndex >= 0 && rowIndex < 5) {
                        defaultValue = 'N/A';
                     } else {
                        defaultValue = 0;
                     }



                     data[row][header] = defaultValue;
                  }
               }
            }
         }

         data['stepAction'] = this.arrStrStepActions[0];
         this.nStepNumber++;
         this.numberForm.controls['stepNumber'].setValue(this.nStepNumber);
         this.handleBeforeModal();
         this.nCurrentStep++;
         const nParamStep = this.nCurrentStep - this.nMainStepNumber;
         this.arrParams.splice(nParamStep, 0, null);
         this.arrObjLoadedParam.splice(nParamStep, 0, null);
         this.strCurrentStepAction = this.arrStrStepActions[0];
         this.handleAfterModal();
         this.arrObjLoadedParam[nParamStep] = data;
      }
   }

   onDeleteStep() {
      if (this.nCurrentStep >= this.nMainStepNumber) {
         const nParamStep = this.nCurrentStep - this.nMainStepNumber;
         if (this.arrParams[1]) {
            this.arrParams.splice(nParamStep, 1);
         }

         if (this.arrObjLoadedParam[1]) {
            this.arrObjLoadedParam.splice(nParamStep, 1);
         }

         if (this.nCurrentStep !== this.nMainStepNumber) {
            this.nStepNumber--;
            this.numberForm.controls['stepNumber'].setValue(this.nStepNumber);
            this.nCurrentStep--;
         } else if (this.nCurrentStep === this.nMainStepNumber) {
            this.nStepNumber--;
            this.numberForm.controls['stepNumber'].setValue(this.nStepNumber);
         }

         this.handleAfterModal();
      }
   }

   onChangeTotalRunTime(event: number) {
      if (!event) {
         this.bIsOverTotalRunTime = false;
      }

      if (this.bIsOverTotalRunTime && event >= this.nMinTotalRunTime) {
         this.bIsOverTotalRunTime = false;
      }

      if (event < this.nMinTotalRunTime) {
         this.bIsOverTotalRunTime = true;
      }
   }

   onUpdateFilter(event: any) {
      let filterValue = event.target.value.toLowerCase();
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;
   }

   fillDefaultObjConfigData(objConfigData) {
      if (!(objConfigData && objConfigData.Current_Modal_Type)) {
         return null;
      }
      const data = this.configData.fillDefaultConfigProps(objConfigData[objConfigData.Current_Modal_Type]);
      return {
         [objConfigData.Current_Modal_Type]: data,
         ...objConfigData,
      };
   }
}
